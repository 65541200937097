html,body, #root{
    height:100%;
}
.active-link{
    color: white;
    background-color: #3973D8 !important;
    text-decoration: none !important;
}
.po-input-box{
    box-sizing: border-box;
    width: 468px;
    height: 55px;
    left: 0px;
    top: 0px;
    border: 1px solid #A1A1A1;
    border-radius: 5px;
}

.po-login-title{
    width: 279px;
    height: 20px;
    left: 54px;
    top: 55px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 2px;
    display: flex;
    align-items: center;
    color: #000000;
}

.po-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 2px;
    display: flex;
    align-items: center;
    color: #000000;
}

.po-login-create{
    width: 263px;
    height: 20px;
    left: 54px;
    top: 247px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 2px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #00B7A5;
}

.po-login-button{
    width: 468px;
    height: 55px;
    left: 54px;
    top: 545px;
    background: #124E87;
    border-radius: 30px;
}

.po-outline{
    padding: 40px;
    position: absolute;
    margin-bottom: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 580px;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}

.po-content-gray{
    width: 468px;
    left: 79px;
    top: 793px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    leading-trim: both;
    text-edge: cap;
    color: #A1A1A1;
}

.po-selectbox-gray{
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 0px;
    border: 2px solid #A1A1A1;
    border-radius: 2px;
}

.dash-main-part{
    height: 90vh;
}
.dash-home{
    max-height:100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.dash-home::-webkit-scrollbar {
    display: none;
}

.header-layout{
    width: 100%;
    height: 10vh;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
}

.dash-record{
    width: 70vh;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.color-accept{
    width: 120px;
    height: 35px;
    background: #3973D8;
    border-radius: 10px;
}
.color-accept:hover {
    background: #4F8DF5;
}
.color-reject{
    width: 120px;
    height: 35px;
    background: #C55300;
    border-radius: 10px;
}
.color-reject:hover {
    background: #FF7A00;
}
.dash-search{
    box-sizing: border-box;
    width: 700px;
    height: 43px;
    background: #FFFFFF;
    border: 1px solid #A1A1A1;
    border-radius: 10px;
}
.head-question-mark{
    font-size: 30px;
}

.detail-font{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    leading-trim: both;
    text-edge: cap;
    color: #000000;
}
.list-scroll{
    max-height: 70vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.list-scroll::-webkit-scrollbar {
    display: none;
}
.button-none{
    border:0;
    padding:0;
    margin:0;
    background: none;
}
.button-size{
    width: 70px;
}

.triangle {
    width: 0;
    height: 0;
    position: relative;
    top: -3px;
    border-left: 20px solid black;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transition: transform 0.3s;
}

.rotate {
    transform: rotate(90deg);
}
.expand-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


