.nav-bar-layout{
    height: 90vh;
    width:240px;
    left: 0px;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
}
.nav-group-item{
    border-radius: 0px;
    width: 100%;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
}